<template>
     <el-dialog title="权限编辑" :visible.sync="DialogShow" append-to-body custom-class='class-dialog' width="700px" @close="onCancel"  :close-on-click-modal='false' :close-on-press-escape='false'>
        <div class="dialog-inside videoDialog">
            <!-- <div class="sunbox">
                <div class="sunbox-text">页面名称</div>
                <div class="sunbox-content" style="width:500px">
                    <el-input v-model="name" placeholder="请输入内容"></el-input>
                </div>
            </div> -->
            <div class="sunbox">
                <div class="sunbox-text">页面名称</div>
                <div class="sunbox-content" style="width:500px">
                    <el-input v-model="name" placeholder="请输入内容"></el-input>
                </div>
            </div>
            <div class="sunbox">
                <div class="sunbox-text">页面描述</div>
                <div class="sunbox-content" style="width:500px">
                    <el-input v-model="description" placeholder="请输入内容"></el-input>
                </div>
            </div>
            <div class="sunbox">
                <div class="sunbox-text">后端路由</div>
                <div class="sunbox-content" style="width:500px">
                    <el-input v-model="route" placeholder="请输入内容"></el-input>
                </div>
            </div>
            <div class="sunbox">
                <div class="sunbox-text">前段链接</div>
                <div class="sunbox-content" style="width:500px">
                    <el-input v-model="url" placeholder="请输入内容"></el-input>
                </div>
            </div>
            <div class="sunbox">
                <div class="sunbox-text">父及id</div>
                <div class="sunbox-content" style="width:500px">
                    <el-cascader style="width:500px" v-model="pid" :options="MenuList" :props="{ value: 'id',label: 'name',checkStrictly: true  }" :show-all-levels="true" @change="onchange"></el-cascader>
                    <!-- <el-input v-model="pid" placeholder="请输入内容"></el-input> -->
                </div>
            </div>

            <div class="sunbox">
                <div class="sunbox-text">图标名称</div>
                <div class="sunbox-content" style="width:500px">
                    <el-input v-model="icon" placeholder="请输入内容"></el-input>
                </div>
            </div>
            <div class="sunbox">
                <div class="sunbox-text">是否菜单栏显示</div>
                <div class="sunbox-content" style="width:500px">
                    <el-radio v-model="is_menu" :label="1">是</el-radio>
                    <el-radio v-model="is_menu" :label="2">否</el-radio>
                </div>
            </div>
            
        </div>
        <div slot="footer" class="dialog-footer">
            <el-button type="default" @click="onCancel" class="button-Dialog">取 消</el-button>
            <el-button type="primary" @click="onSubmit" class="button-Dialog">提 交</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    data() {
        return {
            DialogShow:false,
            goods_name:'',//文案名称


            // UserPrivilegeList:{},//当前用户权限列表
            AllPrivilegeList:{},//权限列表
            MenuList:'',//菜单栏

            //提交参数
            id:"",//用户id
            name:'',//权限名称
            route:'',//后端路由
            description:'',//描述
            pid:'',//pid父级ID
            icon:'',//icon
            is_menu:'',//是否菜单栏显示
            url:'',//前端url
        };
    },
    
    mounted() {
    },

    methods: {
        onDialog(data){
            this.ongetMenu()
            if(data){
                this.id = data.id,//用户id
                this.name = data.name,//权限名称
                this.route = data.route,//后端路由
                this.description = data.description,//描述
                this.pid = data.pid,//pid父级ID
                this.icon = data.icon,//icon
                this.is_menu = data.is_menu,//是否菜单栏显示
                this.url = data.url//前端url
            }
            this.ongetPrivilegeList()
            this.DialogShow = true
        },
        //获取权限列表
        ongetPrivilegeList(){
            var param = {
            }
            this.$service.get(this.$api.getPrivilegeList,param, (res)=> {
                if(res.code == '200'){
                    this.AllPrivilegeList = res.data
                }
            })
        }, 
        // 菜单栏
        ongetMenu(){
            this.MenuList = []
            var param ={}
            this.$service.get(this.$api.getMenu,param, (res)=> {
                if(res.code == '200'){
                    this.MenuList = res.data
                }
            })
        },
        //提交
        onSubmit(){
            if(typeof this.pid == 'number'){
                var pid = this.pid
            }else{
                var pid = this.pid[this.pid.length-1]
            }
            if (this.name == '') {this.$message({ message: "请填写权限名称", type: "warning" });return;}
            if (this.description == '') {this.$message({ message: "请填写描述", type: "warning" });return;}
            if (this.is_menu == '') {this.$message({ message: "请填写是否菜单栏显示", type: "warning" });return;}
            var param = {
                name:this.name,//权限名称
                route:this.route,//后端路由
                description:this.description,//描述
                pid:pid,//pid父级ID
                icon:this.icon,//icon
                is_menu:this.is_menu,//是否菜单栏显示
                url:this.url,//前端url
            }
            this.id ? param.id = this.id : param.id=''
            this.$service.post(this.$api.createPrivilege,param, (res)=> {
                if(res.code == '200'){
                    this.$message({message:'操作成功',type: 'success'})
                    this.$emit('onAllDataList');
                    this.onCancel()
                }
            })
            
        },
        onchange(value){
            console.log(value)
        },
        //取消||关闭触发
        onCancel(){
            this.DialogShow = false
            this.AllPrivilegeList = {},//权限列表
            // this.MenuList = '',//菜单栏
            this.id = "",//用户id
            this.name = '',//权限名称
            this.route = '',//后端路由
            this.description = '',//描述
            this.pid = '',//pid父级ID
            this.icon = '',//icon
            this.is_menu = '',//是否菜单栏显示
            this.url = ''//前端url
        },
    },

    

    // methods: {
    // 测试用
    // onceshi(){
    //  var param = {}
    // 	this.$service.get(this.$api.getWxpublicArt,param, (res)=> {
    // 		console.log(123)
    // 		if(res.code == '200'){

    // 		}
    // 	})
    // },
    // }
};
</script>

<style lang="scss" scoped>
.classifyList{
    .group-table{
        .TableList{
            .el-tag{
                cursor: pointer;
                margin-bottom: 10px;
                margin-right: 20px;
            }
        }
    }
}
</style>
<style lang="scss">
</style>