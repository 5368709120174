<template>
    <div class="directorList">
        <div class="padd20 borbut20 paddboutno0">
            <div class="topSearchSou">
                <div class="searchdan">
                    <div class="title">名称：</div>
                    <el-input v-model="name" placeholder="请输入内容"></el-input>
                </div>
                <div class="searchdan time">
                    <div class="title">创建时间：</div>
                    <el-date-picker v-model="pickerTime" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"></el-date-picker>
                </div>
                <div class="searchdan">
                    <el-button type="primary" icon="el-icon-search" @click="onSearch()">搜索</el-button>
                    <el-button class="Shuarefresh" @click="$router.go(0)" icon='el-icon-refresh-right' >重置数据</el-button>
                </div>
            </div>
        </div>
        <div class="TableList padd20">
            <div class="duoshao">
                <div class="title">权限列表</div>
                <el-button type="primary" @click="onDialog()">添加列表</el-button>
                <div class="lvtotal">总条数：{{lvtotal}} 条</div>
            </div>
            <el-table :data="TableList" empty-text="暂无数据" style="width: 100%;margin-bottom: 20px;" row-key="id" border default-expand-all :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
                <el-table-column prop="name"  label="菜单栏"></el-table-column>
                <el-table-column prop="id"  label="id"></el-table-column>
                <el-table-column prop="url"  label="前台路由"></el-table-column>
                <el-table-column prop="icon"  label="图标"></el-table-column>
                <el-table-column prop="description"  label="页面描述"></el-table-column>
                <el-table-column prop="is_menu"  label="显示状态">
                    <template slot-scope="scope">
                        <span v-if="scope.row.is_menu == 1">显示</span>
                        <span v-else style="color:#f00">关闭</span>
                        <!-- {{scope.row.is_menu == 1 ? '显示' : '关闭'}} -->
                    </template>
                </el-table-column>
                <el-table-column prop="date"  label="操作">
                    <template slot-scope="scope">
                        <el-button type="primary" @click="onDialog(scope.row)" style="margin-left:0">详情修改</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- <el-table :data="TableList" empty-text="暂无数据">
                <el-table-column prop="director_name" label="导演名称"></el-table-column>
                <el-table-column prop="director_sex" label="性别">
                    <template slot-scope="scope">
                        {{scope.row.director_sex == 1 ? '男' : scope.row.director_sex == 2 ? '女' : '未知'}}
                    </template>
                </el-table-column>
                <el-table-column prop="director_mobile" label="联系电话"></el-table-column>
                <el-table-column prop="director_wx_code" label="联系微信"></el-table-column>
                <el-table-column prop="director_email" label="邮箱"></el-table-column>
                <el-table-column prop="director_team" label="团队名称"></el-table-column>
                <el-table-column prop="is_show" label="审核状态">
                    <template slot-scope="scope">
                        {{scope.row.director_sex == 1 ? '男' : scope.row.director_sex == 2 ? '女' : '未知'}}
                    </template>
                </el-table-column>
                <el-table-column prop="date" label="操作" width="200">
                    <template slot-scope="scope">
                        <el-button type="primary" @click="onDialog(scope.row)" style="margin-left:0">操作</el-button>
                    </template>
                </el-table-column>
            </el-table> -->
            <el-pagination class="lv-page" :page-size="per_page" :pager-count="11" @current-change="DialogCurrentChange" background layout="prev, pager, next" :total="lvtotal" hide-on-single-page :current-page.sync="paramPage"></el-pagination>
            <!-- <el-pagination class="lv-page" :page-size="per_page" :pager-count="11" @size-change="handleSizeChange" :page-sizes="[20, 40, 50, 100]" @current-change="DialogCurrentChange" background layout="sizes,prev, pager, next" :total="lvtotal" hide-on-single-page :current-page.sync="paramPage"></el-pagination> -->
        </div>
        <LegeDialog ref="LegeDialog" @onAllDataList='onAllDataList'></LegeDialog>
    </div>
</template>

<script>
import LegeDialog from './legeDialog.vue'
export default {
    data() {
        return {
            paramData:{},//表格参数
            per_page: 20, //一页多少条
            lvtotal: null, //共几条数据
            paramPage:1,//表格当前第几页on


            pickerTime:'',//时间
            name:'',//名称
            TableList:[],//数据列表
        };
    },

    components:{
        LegeDialog
    },
    
    mounted() {
        this.onAllDataList()
    },

    methods: {
        //操作
        onDialog(data){
            this.$refs.LegeDialog.onDialog(data)
        },
        //商品搜索
        onSearch(){
            this.paramData = {
                start_created_at: this.pickerTime ? this.pickerTime[0] : "", //开始日期
                end_created_at: this.pickerTime ? this.pickerTime[1] : "", //结束日期
                name:this.name
            }
            this.paramPage = 1
            this.onAllDataList()
        },
         // 分页
        DialogCurrentChange(val) {
            this.paramPage = val
            this.onAllDataList()
        },
        //数据列表
        onAllDataList(){
            this.TableList = []
            var param = this.paramData
            // param.per_page = this.per_page
            // param.page = this.paramPage
            this.$service.get(this.$api.getPrivilegeList,param, (res)=> {
                if(res.code == '200'){
                    this.TableList = res.data
                    // this.lvtotal = res.data
                    // this.per_page = res.data.per_page
                }
            })
        },
         //每页多少条触发条件
        handleSizeChange(val){
            this.per_page = val
            this.onAllDataList()
        },
    },
    
};
</script>

<style lang="scss" scoped>
</style>
<style lang="scss">
</style>